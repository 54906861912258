import React, { useCallback, useEffect, useState } from 'react';

import { Button } from '@vimeo/iris/components';
import { DismissX } from '@vimeo/iris/icons';
import { themes } from '@vimeo/iris/themes';
import classnames from 'classnames';
import { RawIntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';

import { intl } from '~/helpers/intl';

import Filters from './components/Filters';
import SearchForm from './components/SearchForm';
import SearchResults from './components/SearchResults';
import { clearFilterOptionsSelection } from './helpers';
import styles from './Search.module.scss';
import {
  ALL_FILTER,
  getSearchResults,
  searchEndpointWithParams,
} from './services/search';
import { SearchFilter, SearchResult } from './types';

import 'js/browse/browse-react/browse-card.scss';

export type SearchProps = {
  overlay: boolean;
  site_id: string;
  api_v1_url: string;
  api_v2_url: string;
  initial_search_term?: string;
  user_subscribed: boolean;
  purchased_product_ids: number[];
  theme: string;
  show_filters: boolean;
};

export type TypeFilterCounts = {
  video?: number;
  product?: number;
  collection?: number;
  live_event?: number;
  all: number;
};

const Search = ({
  overlay,
  site_id,
  api_v1_url,
  api_v2_url,
  purchased_product_ids,
  initial_search_term = '',
  user_subscribed,
  theme = 'dark',
  show_filters,
}: SearchProps) => {
  const [locationHref, setLocationHref] = useState<string>('');
  const [showSearch, setShowSearch] = useState<boolean>(!overlay);
  const [searchTerm, setSearchTerm] = useState<string>(
    initial_search_term || '',
  );
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [page, setPage] = useState<number>(1);
  const [typeFilter, setTypeFilter] = useState<string>(ALL_FILTER);
  const [typeFilterCounts, setTypeFilterCounts] = useState<TypeFilterCounts>({
    all: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(
    initial_search_term !== null && initial_search_term.length > 2,
  );
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [searchFilters, setSearchFilters] = useState<SearchFilter[]>([]);
  const [selectedSearchFilters, setSelectedSearchFilters] = useState<
    Record<string, string>
  >({});

  const hideSearch = useCallback(() => {
    setShowSearch(false);
    setSearchTerm('');
    setSearchResults([]);
    setSearchFilters(clearFilterOptionsSelection(searchFilters));
    setSelectedSearchFilters({});
    setIsLoading(false);
    setTypeFilter(ALL_FILTER);
    window.history.pushState({}, '', locationHref || window.location.origin);
  }, [locationHref, searchFilters]);

  useEffect(() => {
    // Show similar items found in the search by anchors (tags).
    // Required data attributes: data-meta-field-name, data-meta-field-value.
    if (overlay) {
      const metaDataLinks: HTMLElement[] = Array.from(
        document.querySelectorAll('.meta-data-link'),
      );

      const showSearch = (event: MouseEvent) => {
        let fieldName, fieldValue;
        const currentTarget = event.currentTarget as HTMLElement;
        event.preventDefault();

        if (currentTarget !== null) {
          fieldName = currentTarget.dataset.metaFieldName;
          fieldValue = currentTarget.dataset.metaFieldValue;
          setShowSearch(true);
          setSearchTerm(fieldName + ': ' + fieldValue);
          setLocationHref(window.location.href);
        }
      };

      metaDataLinks.forEach((link: HTMLElement) => {
        link.addEventListener('click', showSearch);
      });

      return function cleanup() {
        metaDataLinks.forEach((link: HTMLElement) => {
          link.removeEventListener('click', showSearch);
        });
      };
    }
  }, [locationHref, overlay, hideSearch]);

  useEffect(() => {
    if (overlay) {
      const searchNavButton = document.getElementById('search-nav-toggle');

      const hideSearchFromEsc = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          hideSearch();
        }
      };

      const showSearch = (event: MouseEvent) => {
        event.preventDefault();
        setShowSearch(true);
        setLocationHref(window.location.href);
      };

      if (searchNavButton) {
        searchNavButton.addEventListener('click', showSearch);
      }

      document.addEventListener('keydown', hideSearchFromEsc);

      return function cleanup() {
        if (searchNavButton) {
          searchNavButton.removeEventListener('click', showSearch);
        }

        document.removeEventListener('keydown', hideSearchFromEsc);
      };
    }
  }, [locationHref, overlay, hideSearch]);

  useEffect(() => {
    const shouldSubmit =
      searchTerm.length >= 3 || Object.keys(selectedSearchFilters).length;

    if (shouldSubmit) {
      setIsLoading(true);
    }

    const submitSearch = async () => {
      if (shouldSubmit) {
        try {
          const response = await getSearchResults(
            searchTerm,
            site_id,
            api_v2_url,
            selectedSearchFilters,
            1,
            typeFilter,
          );
          window.history.pushState(
            {},
            '',
            window.location.origin + searchEndpointWithParams(searchTerm),
          );
          if (searchTerm) {
            window.Stats.trackEvent(
              window.Tracking.GOOGLE_ANALYTICS_4.EVENTS.SEARCH,
              { search_term: searchTerm },
            );
          }
          setIsLoading(false);
          setSearchResults(response.results);
          setTypeFilter(ALL_FILTER);
          setTypeFilterCounts({
            ...response.type_counts,
            all: response.pagination.count,
          });
        } catch {
          setIsLoading(false);
          setSearchResults([]);
        }
      } else {
        setIsLoading(false);
        if (!searchTerm.length) {
          setSearchResults([]);
          setTypeFilterCounts({ all: 0 });
        }
      }
    };

    const delaySearch = setTimeout(() => {
      submitSearch();
    }, 500);

    return () => clearTimeout(delaySearch);
  }, [searchTerm, site_id, api_v2_url, selectedSearchFilters]);

  const loadMoreResults = async () => {
    try {
      setIsLoadingMore(true);
      const response = await getSearchResults(
        searchTerm,
        site_id,
        api_v2_url,
        selectedSearchFilters,
        page + 1,
        typeFilter,
      );
      setPage(page + 1);
      setSearchResults([...searchResults, ...response.results]);
      setIsLoadingMore(false);
    } catch {
      setIsLoadingMore(false);
    }
  };

  const updateTypeFilter = async (filter: string) => {
    if (filter !== typeFilter) {
      try {
        setIsLoading(true);
        const response = await getSearchResults(
          searchTerm,
          site_id,
          api_v2_url,
          selectedSearchFilters,
          1,
          filter,
        );
        setPage(1);
        setTypeFilter(filter);
        setIsLoading(false);
        setSearchResults(response.results);
      } catch {
        setIsLoading(false);
      }
    }
  };

  return (
    <RawIntlProvider value={intl}>
      <ThemeProvider theme={themes.light}>
        <div
          className={classnames(styles.contentContainer, {
            [styles.show]: showSearch,
            [styles.hide]: !showSearch,
            [styles.overlay]: overlay,
            [styles.light]: theme === 'light',
          })}
          data-testid='search-test-container'
        >
          <div className={styles.searchContainer}>
            {overlay && (
              <Button
                variant='minimalTransparent'
                format='secondary'
                onClick={hideSearch}
                icon={<DismissX className={styles.icon} />}
                className={classnames(styles.closeButton, {
                  [styles.light]: theme === 'light',
                })}
                data-testid='search-test-close-button'
              />
            )}
            <SearchForm
              searchTerm={searchTerm}
              updateSearchTerm={setSearchTerm}
              theme={theme}
            />
            <div className={styles.resultsContentContainer}>
              {show_filters && (
                <Filters
                  searchFilters={searchFilters}
                  handleSearchFiltersChange={setSearchFilters}
                  handleSelectedFiltersUpdate={setSelectedSearchFilters}
                  siteId={site_id}
                  apiV2Url={api_v2_url}
                  theme={theme}
                />
              )}

              <SearchResults
                searchResults={searchResults}
                userSubscribed={user_subscribed}
                apiV1Url={api_v1_url}
                purchasedProductIds={purchased_product_ids}
                searchTerm={searchTerm}
                isLoading={isLoading}
                handleLoadMore={loadMoreResults}
                isLoadingMore={isLoadingMore}
                typeFilter={typeFilter}
                typeFilterCounts={typeFilterCounts}
                handleTypeFilterChange={updateTypeFilter}
                theme={theme}
                withFilters={searchFilters.length > 0}
              />
            </div>
          </div>
        </div>
      </ThemeProvider>
    </RawIntlProvider>
  );
};

export default Search;
